// 全局配置菜单文件
// 后台logo配置 navBar后台菜单配置
export default {
	logo: '钟宏食品管理系统',
	navBar: {
		active: '0',
		list: [{
				name: "首页",
				subActive: '0',
				submenu: [{
					icon: "el-icon-s-home",
					name: "后台首页",
					pathname: "index"
				}, {
					icon: "el-icon-picture",
					name: "相册管理",
					pathname: "image"
				}, {
					icon: "el-icon-s-claim",
					name: "app首页设置",
					pathname: "app_index"
				}]
			},{
				name: "商品",
				subActive: '0',
				submenu: [{
					icon: "el-icon-s-claim",
					name: "商品列表",
					pathname: "shop_goods_list"
				},{
					icon: "el-icon-s-help",
					name: "分类管理",
					pathname: "shop_category_list"
				},{
					icon: "el-icon-s-order",
					name: "商品规格",
					pathname: "shop_sku_list"
				},{
					icon: "el-icon-menu",
					name: "商品类型",
					pathname: "shop_type_list"
				},{
					icon: "el-icon-edit-outline",
					name: "商品评论",
					pathname: "shop_comment_list"
				},{
					icon: "el-icon-edit-outline",
					name: "抽奖设置",
					pathname: "cjsz_cjsz"
				},{
					icon: "el-icon-edit-outline",
					name: "抽奖记录",
					pathname: "cjsz_cjjl"
				},{
					icon: "el-icon-edit-outline",
					name: "积分商城",
					pathname: "jf_jfsc"
				},{
					icon: "el-icon-edit-outline",
					name: "兑换记录",
					pathname: "jf_jfscjl"
				},{
					icon: "el-icon-edit-outline",
					name: "积分设置",
					pathname: "jf_jfsz"
				}]
			},
			{
				name: "订单",
				subActive: '0',
				submenu: [{
					icon: "el-icon-s-order",
					name: "订单列表",
					pathname: "order_order_list"
				},{
					icon: "el-icon-s-ticket",
					name: "发票管理",
					pathname: "order_invoice_list"
				},{
					icon: "el-icon-s-custom",
					name: "售后服务",
					pathname: "order_after-sale_list"
				},]
			},
			{
				name: "会员",
				subActive: '0',
				submenu: [{
					icon: "el-icon-s-custom",
					name: "会员列表",
					pathname: "user_user-list_list"
				},{
					icon: "el-icon-medal-1",
					name: "会员等级",
					pathname: "user_user-level_list"
				},]
			},
			{
				name: "设置",
				subActive: '0',
				submenu: [{
					icon: "el-icon-s-tools",
					name: "基础设置",
					pathname: "set_base"
				},{
					icon: "el-icon-truck",
					name: "物流设置",
					pathname: "set_express"
				},{
					icon: "el-icon-s-platform",
					name: "管理员列表",
					pathname: "set_manager"
				},{
					icon: "el-icon-s-finance",
					name: "交易设置",
					pathname: "set_payment"
				},]
			},
		]
	}

}
